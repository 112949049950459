@import "../../../../styles/colours.scss";

.detailsTab {
	.editUser-detailColumn {
		padding: 0 0.5em;

		.editUser-formField {
			margin-bottom: 1em;

			.Mui-disabled {
				background-color: $beige;
			}
		}
	}
}
