@import "../../styles/colours.scss";

.homeView {
  .homeView-title {
    margin: 2em 0;
  }
}
.homeView.MuiBox-root.css-0 {

  padding-left: 50px;
  padding-right: 50px;

  -webkit-box-shadow: inset 10px 10px 35px -15px rgba(57,57,57,0.55);
  -moz-box-shadow: inset 10px 10px 35px -15px rgba(57,57,57,0.55);
  box-shadow: inset 10px 10px 35px -15px rgba(57,57,57,0.55);

  min-width: 95%;
}