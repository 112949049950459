@import "../../styles/colours.scss";
.AppName{
	font-size: x-large;
}

.app-header {
	height: 76px;
	min-height: 76px;
	background-color: $primaryBlue;
	color: $white;

	.header-wrapper .MuiBox-root .css-0 {
		background-color: $primaryBlue;
	}

	.header-wrapper {
		height: 100%;
		.header-column.header-left {
			background-color: $darkGray;
			min-width:300px;
		}
		header-column.header-right {
			min-width: 400px;
		}
		.header-column {
			height: 100%;

			.header-link-box {
				height: 100%;
				margin: -8px 1em 0 1em;
				/*border-top: 8px solid transparent;
				padding: 0 1em;*/

				&.header-link-red {
					border-color: red;
				}

				.header-link {
					align-items: center;
				}
			}
		}
	}
}
