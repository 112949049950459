@import "../../../../styles/colours.scss";

$formBorderGray: #888888;
$unselectedTabBackground: #e5e5e5;

.editCompanyForm-container {
  background-color: $beige;
  padding: 1em;

  .editCompanyForm-header {
    border-bottom: 1px solid $formBorderGray;
    margin-bottom: 1.5em;

    .editCompanyForm-tabs {
      min-height: 0;
      overflow: visible;

      .MuiTabs-scroller {
        overflow: visible !important;
      }

      .tab {
        margin: 0 0.25em;
        margin-bottom: -1px;
        border: 1px solid $formBorderGray;
        min-width: 0;
        min-height: 0;
        padding: 4px 10px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &.Mui-selected {
          border-bottom-color: $beige;
          color: $black;
        }

        &:not(.Mui-selected) {
          background-color: $unselectedTabBackground;
        }

        .tab-label {
          text-transform: none;
          font-family: "Poppins";

          .icon {
            margin-right: 0.25em;
          }

          .icon-toggle {
            color: $primaryBlue;
          }

          .icon-key {
            color: $orange;
          }

          .icon-person {
            color: $black;
          }
        }
      }
    }
  }

  .editCompany-tab-content {
    padding: 0 1em;

    .tab-title {
      margin-bottom: 0.5em;
    }
  }

  .buttons {
    .button {
      width: 95px;
      margin: 0 0.5em;
    }
  }
}
