@import "../../../../styles/colours.scss";

.detailsTab {
  .editCompany-detailColumn {
    padding: 0 0.5em;

    .editCompany-formField {
      margin-bottom: 1em;

      .Mui-disabled {
        background-color: $beige;
      }
    }
  }
}
