@import "../../../styles/colours.scss";

.formField-label.MuiInputLabel-root {
	font-size: 0.65em;
}

.formField {
	margin-bottom: 0.5em;

	&.MuiInputBase-root {
		background-color: $white;
		font-family: "Poppins";
	}

	.MuiFilledInput-root {
		background-color: $white;
		font-family: "Poppins";
	}

	.MuiSelect-select {
		padding-top: 10px;
		font-family: "Poppins";
	}
}

.formField-multiselect-item .MuiListItemText-primary {
	font-family: "Poppins";
}
