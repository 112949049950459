@import "../../../../styles/colours.scss";

.newUser-container {
	padding: 0.5em 0.5em 1em 1em;
	background-color: $beige;

	.newUser-columns {
		margin-top: 1em;

		.newUser-column {
			margin: 0 1em;

			.newUser-column-header {
				border-bottom: 1px solid $lightGray;
				padding-bottom: 0.25em;
				margin-bottom: 1em;

				.icon {
					font-size: 2em;
					margin-right: 0.5em;

					&.icon-person {
						color: $darkGray;
					}

					&.icon-key {
						color: $orange;
					}

					&.icon-toggle {
						color: $primaryBlue;
					}

					&.icon-envelope {
						color: $darkGray;
					}
				}
			}

			.newUser-formField {
				margin-bottom: 0.5em;
			}

			.passwordInput-hint,
			.roles-hint,
			.templates-hint {
				font-size: 0.8em;
				color: $lightGray;
			}

			.suggestPassword-button {
				width: 110px;
				background-color: $orange;
				color: $black;
				font-family: "Poppins";
			}

			.template-radio .MuiFormControlLabel-label {
				font-family: "Poppins";
			}
		}
	}

	.button {
		margin: 0 0.5em;

		&.cancel-button {
			background: $lightGray;
			color: $black;
		}
	}
}
