.trimmedNav {
  width: 250px;
  transition: width 0.3s ease-in-out;
  height: 100vh;
  background-color: rgb(57,57,57);
  padding-top: 28px;
}
.trimmedNavClosed {
  composes: trimmedNav;
  transition: width 0.3s ease-in-out;
  width: 60px
}
.trimmedItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #B2BAC2;
  text-decoration: none;
  overflow: hidden;
}
.linkText {
  padding-left: 16px;
  font-size: large;
}

.trimmedItem:hover {
  background-color: #244f7d1c;

}
.menuBtn {
  align-self: center;
  align-self: flex-start;
  justify-self: flex-end;
  color: #B2BAC2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}
