@import "../../../styles/colours.scss";

.usersTable-container {
	.usersTable-loadingIndicator {
		margin: 2em;
		width: 100%;
		justify-content: center;
		-webkit-box-pack: center;
	}

	.button-addUser {
		font-family: "Poppins";
	}

	.search-field {
		.MuiInputBase-input {
			font-family: "Poppins";
		}
	}

	.usersTable {
		margin-top: 1.5em;

		.usersTable-loadingIndicator {
			width: auto;
		}

		.usersTable-headerCell {
			cursor: pointer;
			border-bottom-width: 2px;
			border-bottom-color: $lightGray;

			.icon-sort {
				&.selected {
					color: $primaryBlue;
				}
			}
		}

		.loginColumn-content {
			max-width: 450px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.usersTable-newUserRow {
			padding: 0;
			border: none;
		}
	}
}
