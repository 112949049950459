@import "../../../styles/colours.scss";

.companiesTable-row {
  &.opened {
    background-color: $beige;

    .MuiTableCell-root {
      border-bottom: none;
    }
  }

  &.disabled .MuiTableCell-root {
    color: $lightGray;
  }

  .MuiTableCell-root {
    font-family: "Poppins";
  }

  .status-switch .MuiFormControlLabel-label {
    font-family: "Poppins";
  }
}

.dialog {
  .dialog-title {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    color: $primaryBlue;
  }

  .dialog-actions {
    justify-content: space-between;

    .button {
      width: 45%;
    }

    .button-dialogConfirm {
      color: $black;
      background-color: $orange;
    }
  }
}