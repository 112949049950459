@import "../../../styles/colours.scss";

.companiesTable-container {
  .companiesTable-loadingIndicator {
    margin: 2em;
    width: 100%;
    justify-content: center;
    -webkit-box-pack: center;
  }

  .button-addCompany {
    font-family: "Poppins";
  }

  .icon-addCompany {
    padding-right: 5px;
  }

  .search-field {
    .MuiInputBase-input {
      font-family: "Poppins";
    }
  }

  .companiesTable {
    margin-top: 1.5em;

    .companiesTable-loadingIndicator {
      width: auto;
    }

    .companiesTable-headerCell {
      cursor: pointer;
      border-bottom-width: 2px;
      border-bottom-color: $lightGray;

      .icon-sort {
        &.selected {
          color: $primaryBlue;
        }
      }
    }

    .loginColumn-content {
      max-width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .companiesTable-newCompanyRow {
      padding: 0;
      border: none;
    }
  }
}
