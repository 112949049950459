@import "../../../../styles/colours.scss";

.passwordTab {
	.hint {
		font-size: 0.75em;
		margin-bottom: 0.5em;
	}

	.password-field {
		margin-bottom: 0.5em;
	}

	.suggestPassword-button {
		width: 110px;
		background-color: $orange;
		color: $black;
	}

	.template-radio .MuiFormControlLabel-label {
		font-family: "Poppins";
	}
}
