@import "../../../../styles/colours.scss";

.rolesTab {
	.userDisabled-warning {
		margin-left: 1em;
		padding: 0.25em;
		background: $red;
		color: $white;
	}

	.editUser-formField {
		width: 400px;

		&.Mui-focused,
		&:hover {
			background-color: $white;
		}

		.MuiInputBase-input:focus {
			background-color: $white;
		}
	}
}
