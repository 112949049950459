@import "./colours.scss";

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins.woff2") format("woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins.woff2") format("woff2");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
	margin: 0;
	height: 100vh;
	font-family: "Poppins";

	#root {
		height: 100%;
		display: flex;
		flex-flow: column;
	}
}

a {
	color: #0366d6;
}

code {
	color: #e01a76;
}

footer {
	padding-bottom: 1em;
}

.button.MuiButton-root {
	text-transform: none;
	font-family: "Poppins";
}

.button.primary-button {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

.button.secondary-button {
	background-color: $lightGray;
	color: $black;
}

.link {
	color: black;
	text-decoration: none;
}
.pagecontent-container {
  background-color: white;
  border-top-left-radius: 20px;
}

.leftnav-container {
  background-color: rgb(57,57,57);
}
.unauthorized-title {
  padding: 100px;
}